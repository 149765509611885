<template>
  <div id="main-page">
    <div class="container">
      <aside>
        <app-bar
          title="Detail Promo"
          :noDivider="true"
          :previousLink="{ name: 'UseVoucher' }"
        />
      </aside>
      <div class="row">
        <div class="col-12 px-0 pt-4 pt-md-0 mt-3 mt-md-0">
          <div class="banner bg-fuschia-80 d-flex align-items-center justify-content-between p-3">
            <div class="banner-group">
              <div class="fw-300 fs-12 lh-24 color-fuschia-80 mb-1">GIFT VOUCHER</div>
              <p class="fw-700 fs-18 lh-28 text-white" style="max-width: 163px">Promo Premarital Excellent</p>
            </div>
            <div class="banner-image">
              <img src="@/assets/icon/drugs-2.svg" alt="Icon">
            </div>
          </div>
        </div>
        <div class="col-12 pt-3 px-3">
          <div class="discount mb-3">
            <div class="discount-title fw-700 fs-14 lh-24 color-neutral-900 mt-md-3 mb-1">
              Diskon Rp. 100.000
            </div>
            <div class="discount-group d-flex align-items-center justify-content-between fw-400 fs-12 lh-22 color-neutral-600">
              <div class="d-flex align-items-center gap-1">
                <i class="ri-time-line fs-16 color-secondary-500"></i>
                <span>Berlaku hingga</span>
              </div>
              <span>18 Desember 2021</span>
            </div>
          </div>
          <div class="content d-flex flex-column">
            <div class="content-title fw-700 fs-14 lh-24 color-neutral-900 mt-md-3 mb-1">
              Syarat dan Ketentuan
            </div>
            <p class="content-desc fw-400 fs-14 lh-24 color-neutral-600 mb-0">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam suscipit eget libero ac ullamcorper. Vivamus ac tincidunt ex. Praesent quis scelerisque libero. Nunc dapibus velit non rutrum faucibus. Suspendisse pellentesque orci ut diam fermentum malesuada. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur eget interdum elit. Nulla ex justo, fermentum ac accumsan in, auctor sit amet urna.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar.vue'

export default {
  components: {
    AppBar
  }
}
</script>
